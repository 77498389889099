import React from 'react';
import PropTypes from 'prop-types';

import './services.scss';

const Services = ({ services, heading = "Nossos Serviços" }) => {
  return (
    <section className="services-section">
      <h2 className="services-heading">{heading}</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

Services.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  heading: PropTypes.string,
};

export default Services;
