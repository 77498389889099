import React, { useEffect, useRef } from 'react';
import './system.scss';
import ContactForm from '../form/form';
import ZigZag from '../ZigZag/ZigZag';
import { useTranslation } from 'react-i18next';



function System() {
  const { t } = useTranslation();


  return (
    <div className="system">
      <div className="header-image">
        <h1 className = "system-heading">{t('system_page_title')}</h1>
        <img src="/images/techField.webp" alt="Header" />
      </div>
      <ZigZag
        items={[
          {
            imageSrc: "/images/Banner_1.webp",
            imageAlt: "system",
            title: t('system_page_content_1_title'),
            description: t('system_page_content_1_text'),
            listItems: [
              t('system_page_list_1_item_1'),
              t('system_page_list_1_item_2'),
              t('system_page_list_1_item_3'),
              t('system_page_list_1_item_4'),
              t('system_page_list_1_item_5')
            ],
            ctaButton : t('system_page_button_2'),
            ctaLink : "https://www.youtube.com/watch?v=snbvTYHi5x0",
          },
          {
            imageSrc: "/images/Banner_2.webp",
            imageAlt: "system",
            title: t('system_page_content_2_title'),
            description: t('system_page_content_2_text_1') + '\n\n' + t('system_page_content_2_text_2') + '\n\n' + t('system_page_content_2_text_3'),
            ctaButton: t('system_page_button'),
            ctaLink: 'https://allentysistemadeinventario.com.br/inventario/login.aspx'
          }
        ]}
      />
      <ContactForm/>
    </div>
  );
}

export default System;