import React, { useEffect, useRef } from 'react';
import './aboutUs.scss';  // Import custom SCSS for styling
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const aboutUsRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          aboutUsRef.current.classList.add('fade-in');
          observer.disconnect();
        }
      },
      {
        threshold: 0.1, // Adjust threshold for better performance on smaller screens
      }
    );

    if (aboutUsRef.current) {
      observer.observe(aboutUsRef.current);
    }

    return () => {
      if (aboutUsRef.current) {
        observer.unobserve(aboutUsRef.current);
      }
    };
  }, []);

  return (
    <div className="about-us-container" ref={aboutUsRef}>
        <h1 className='text-center my-4'>{t('about_us')}</h1>
      <div className="row">
        <div className="col-md-6 about-left">
          <h1>
          {t('about_us_content_1')}  <span style={{ color: '#0056b3' }}>{t('about_us_content_2')}<br/> {t('about_us_content_3')} <br/>{t('about_us_content_4')}</span><br/> {t('about_us_content_5')}
          </h1>
          <p className="description">
            {t('about_us_content_6')}
          </p>
        </div>
        <div className="col-md-6 about-right">
          <div className="statistics">
            <div className="stat">
              <h2>15+</h2>
              <p>{t('about_years')}</p>
            </div>
            <div className="stat">
              <h2>500+</h2>
              <p>{t('about_projects')}</p>
            </div>
            <div className="stat">
              <h2>300+</h2>
              <p>{t('about_clients')}</p>
            </div>
            <div className="stat">
              <h2>1M+</h2>
              <p>{t('about_assets')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center my-4">
        <a href={`/${i18n.language}/${t('routes.cases')}`}><button className="btn btn-primary custom-button">{t('learn_more')}</button></a>
      </div>
    </div>
  );
}

export default AboutUs;