import React from 'react';
import CarouselComponent from './home-carousel/home-carousel';
import FeatureCards from './system-cards/feature-cards';
import AboutUs from './aboutUs/aboutUsPage';
import Clients from '../clients/clients';
import MapWithInfo from './map/map';
import ContactForm from '../form/form';
import { FaBalanceScale , FaMobileAlt, FaBox  } from 'react-icons/fa';
import { LuNotebookPen } from "react-icons/lu";
import { IoDocumentOutline } from "react-icons/io5";
import { BsClipboard2Check } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import Services from './services/services';


const Home = () => {
  const { t, i18n } = useTranslation();

  return (
   <>    
    <CarouselComponent/>
    <AboutUs/>
    <Clients/>

    <FeatureCards 
      cardsData={[
        {
          imageSrc: "/images/cloud_ilustration.webp",
          imageAlt: "Card 1",
          title: t('system_card_title_1'),
          content: t('system_card_content_1')
        },
        {
          imageSrc: "/images/android_ilustration.webp",
          imageAlt: "Card 2",
          title: t('system_card_title_2'),
          content: t('system_card_content_2')

        },
        {
          imageSrc: "/images/anywhere.ilustration.webp",
          imageAlt: "Card 3",
          title: t('system_card_title_3'),
          content: t('system_card_content_3')
        }
      ]}
      heading={t('system_card_heading')} 
      buttonText={t('learn_more')}
      buttonLink={`/${i18n.language}/${t('routes.system')}`}
    />    
    <Services
            services = {[
              {
                icon: <BsClipboard2Check/>,
                title: t('home_services.fixed_asset.title'),
                description: t('home_services.fixed_asset.description')
              },
              {
                icon: <FaBalanceScale />,
                title: t('home_services.evaluation.title'),
                description: t('home_services.evaluation.description')
              },
              {
                icon: <LuNotebookPen />,
                title: t('home_services.conciliation.title'),
                description: t('home_services.conciliation.description')
              },
              {
                  icon: <FaBox />,
                  title: t('home_services.stock.title'),
                  description: t('home_services.stock.description')
                },
                {
                  icon: <FaMobileAlt />,
                  title: t('home_services.system.title'),
                  description: t('home_services.system.description')
                },
                {
                  icon: <IoDocumentOutline />,
                  title: t('home_services.documents.title'),
                  description: t('home_services.documents.description')
                },
            ]}
            heading={t('home_services_heading')} 
            />
    <MapWithInfo/>
    <ContactForm/>
   </>
  );
};

export default Home;