import React, { useEffect, useState } from 'react';
import './system-cards.scss';  // Import custom SCSS for card styling
import { useTranslation } from 'react-i18next';

// Create a reusable Card component
const Card = ({ imageSrc, imageAlt, title, content }) => {
  return (
    <div className="col-md-4">
      <div className="card custom-card">
        <img src={imageSrc} className="card-img-top" alt={imageAlt} />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{content}</p>
        </div>
      </div>
    </div>
  );
};

// Make SystemCards accept props with default values
function SystemCards({ 
  cardsData = null, 
  heading = null, 
  buttonText = null, 
  buttonLink = null 
}) {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );
  }, []);

  const cardItems = cardsData || [];

  // Use provided heading or fall back to translation
  const displayHeading = heading || t('system_card_heading');
  
  // Use provided button text/link or fall back to translations
  const displayButtonText = buttonText || t('learn_more');
  const displayButtonLink = buttonLink || t('routes.system');

  return (
    <div className="container my-4">
      <h2 className="text-center my-4">
        {displayHeading} 
      </h2>

      <div className="row">
        {cardItems.map((card, index) => (
          <Card
            key={index}
            imageSrc={card.imageSrc}
            imageAlt={card.imageAlt}
            title={card.title}
            content={card.content}
          />
        ))}
      </div>

      <div className="text-center my-4">
        <a target="_blank" href={displayButtonLink}>
          <button  className="btn btn-primary custom-button">{displayButtonText}</button>
        </a>
      </div>
    </div>
  );
}

export default SystemCards;