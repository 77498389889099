import React from 'react';
import './ZigZag.scss';

const ZigZagItem = ({ 
  imageSrc, 
  imageAlt, 
  title, 
  description, 
  isReversed,
  ctaButton,
  ctaLink,
  listItems = [], // Add optional list items array
  imageAttribution, // Add optional image attribution text
  imageAttributionLink // Add optional image attribution link
}) => {
  return (
    <div className={`zigzag-item ${isReversed ? 'reversed' : ''}`}>
      <div className="zigzag-content">
        <h3>{title}</h3>
        <p>{description}</p>
        
        {/* Render list items if provided */}
        {listItems.length > 0 && (
          <ul className="zigzag-list">
            {listItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
        
        {ctaButton && ctaLink && (
          <a target="_blank" href={ctaLink} className="zigzag-cta">
            {ctaButton}
          </a>
        )}
      </div>
      <div className="zigzag-image">
        <img src={imageSrc} alt={imageAlt} />
        {imageAttribution && (
          <div className="image-attribution">
            {imageAttributionLink ? (
              <a href={imageAttributionLink} target="_blank" rel="noopener noreferrer">
                {imageAttribution}
              </a>
            ) : (
              imageAttribution
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const ZigZag = ({ heading, items }) => {
  return (
    <section className="zigzag-section">
      {heading && <h2 className="zigzag-heading">{heading}</h2>}
      <div className="zigzag-container">
        {items.map((item, index) => (
          <ZigZagItem
            key={index}
            imageSrc={item.imageSrc}
            imageAlt={item.imageAlt}
            title={item.title}
            description={item.description}
            isReversed={index % 2 !== 0}
            ctaButton={item.ctaButton}
            ctaLink={item.ctaLink}
            listItems={item.listItems} // Pass the list items
            imageAttribution={item.imageAttribution} // Pass the image attribution text
            imageAttributionLink={item.imageAttributionLink} // Pass the image attribution link
          />
        ))}
      </div>
    </section>
  );
};

export default ZigZag;