import React from 'react';
import './Hero.scss';
import { useTranslation } from 'react-i18next';
function Hero() {
  const { t } = useTranslation();

  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="hero-text">
          <h1>{t('landing_page.header.title')}</h1>

          <h2>{t('landing_page.header.text')}</h2>
          <a href="#contact-form"><button className="cta-button">{t('landing_page.header.button')}</button></a>
        </div>
        <div className="hero-image">
          <img src="/images/6934625.jpg" alt="Technology Solutions" />
          <div className="image-attribution">
              <a href="https://www.freepik.com/" target="_blank" rel="noopener noreferrer">
                Designed by Freepick
              </a>            
        </div>
        </div>
      </div>

    </div>
  );
}

export default Hero; 