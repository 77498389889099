import React from 'react';
import './whatsapp.scss'; // For styling

const WhatsAppWidget = () => {
  const phoneNumber = '551143285068';  // Replace with your WhatsApp number (in international format, no +)

  const handleClick = () => {
    window.gtag('config', 'AW-976473031')
    window.gtag('event', 'conversion', {
      'send_to': 'AW-976473031/Ur-fCN6ymoIaEMeXz9ED',
      'value': 0,
      'currency': 'BRL',
      'transaction_id': ''
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'whatsapp_click'
    });
    window.open(`https://wa.me/${phoneNumber}?`, '_blank');

  };

  return (
    <div className="whatsapp-widget" onClick={handleClick}>
      <img src="/images/whatsapp.svg" alt="WhatsApp" />
    </div>
  );
};

export default WhatsAppWidget;
