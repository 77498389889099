import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import axios from 'axios';
import './form.scss';  // Import custom SCSS for styling
import { useTranslation } from 'react-i18next';

function ContactForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    services: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        services: checked
          ? [...prevData.services, value]
          : prevData.services.filter((service) => service !== value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


//   const handleSubmit = async (e)  => {
//     e.preventDefault();
//     setIsLoading(true);

//     const serviceId = 'service_7jothkd';
//     const templateId = 'template_mzb08gf';
//     const publicKey = 'iQFyJLqNzQi_jk_I5';

//     const template_params = {
//       name: formData.name,
//       email: formData.email,
//       phone: formData.phone,
//       services: formData.services.join(', '),
//   }

//     await emailjs.send(serviceId, templateId, template_params, publicKey)
//     .then((response) => {
//       setIsLoading(false);
//       setIsSubmitted(true);
//       console.log('Email sent successfully!', response);
//       if (window.gtag) {
//         window.gtag('event', 'conversion', {
//         'send_to': 'AW-976473031/YL3jCNuymoIaEMeXz9ED',
//         'value': 0,
//         'currency': 'BRL',
//         });
//       }

//     })
//     .catch((error) => {
//       console.error('Error sending email:', error);
//     });
// }

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  const serviceId = 'service_7jothkd';
  const templateId = 'template_mzb08gf';
  const publicKey = 'iQFyJLqNzQi_jk_I5';

  const form = e.target;
  
  try {
    // Using sendForm instead of send - this directly takes the form data
    // and is generally more efficient for form submissions
    const response = await emailjs.sendForm(
      serviceId,
      templateId,
      form,
      publicKey
    );
    window.gtag('config', 'AW-976473031')
    window.gtag('event', 'conversion', {
      'send_to': 'AW-976473031/YL3jCNuymoIaEMeXz9ED',
      'value': 0,
      'currency': 'BRL',
      'transaction_id': ''
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'form_submission',
      'formName': 'contact_form'
    });
    
    setIsSubmitted(true);
    form.reset(); // Reset the form after successful submission
  } catch (error) {
    console.error('Error sending email:', error);
    // Consider adding error handling for the user here
  } finally {
    setIsLoading(false);
  }
};


  return (

    <div id="contact-form" className="contact-section">
    <div className="row">
      <div className="col-md-6 contact-text">
        <h1>{t('form_text_title')}</h1>
        <p>
          {t('form_content_1')} <br /><br />{t('form_content_2')}
        </p>
      </div>
      <div className="col-md-6 form-container">
        <div className="contact-form-container">
          {isSubmitted ? (
            <div className="thank-you-message">
              <h3>{t('form_thankyou_title')}</h3>
              <p>{t('form_thankyou_content')}</p>
            </div>
          ) : isLoading ? (
            <div className="loading-overlay">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t('form_loading')}</span>
              </div>
              <p>{t('form_loading')}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <h3 className="form-title">{t('form_title')}</h3>
              {/* Form fields remain the same as before */}
              <div className="form-group">
                <label htmlFor="name">{t('form_name')}</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t('form_phone')}</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <div className="row">
                <div className="form-group">
                <label>{t('form_services')}</label>
                <div className="row">
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="service1"
                        name="services"
                        value="Asset Inventory"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="service1">{t('form_fixed_asset')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="service2"
                        name="services"
                        value="Accounting Reconciliation"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="service2">{t('form_reconciliation')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="service3"
                        name="services"
                        value="Asset Valuation"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="service3">{t('form_evaluation')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="service4"
                        name="services"
                        value="Fixed Asset Management"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="service4">{t('form_asset_management')}</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="service5"
                        name="services"
                        value="Inventory Systems"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="service5">{t('form_system')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="service6"
                        name="services"
                        value="Stock Inventory"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="service6">{t('form_stock')}</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="service7"
                        name="services"
                        value="Stock Inventory Support"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="service7">{t('form_support')}</label>
                    </div>
                  </div>
                </div>
              </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {t('form_submit')}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  </div>
  );
}



export default ContactForm;