// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import { BrowserRouter as Router, Routes, Route,Outlet } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import Navibar from './components/navbar/navbar';
// import Home from './components/home/home';
// import System from './components/system/system';
// import Inventory from './components/inventory/inventory';
// import Cases from './components/cases/cases';
// import Impairment from './components/impairment/impairment';
// import LandingPage from './components/landingPage/landingPage';
// import WhatsAppWidget from './components/whatsapp/whatsapp';
// import './App.css';
// import Footer from './components/footer/footer';
// import NotFound from './components/404/404';


// function App() {

//   const { t, i18n } = useTranslation();


//   return (
//     <>
//     <Navibar/>

//     <WhatsAppWidget/>

//     <Router>
//       <Routes>
//         <Route path="/" element={<Home/>}/>
//         <Route path= {`${t('routes.system')}`} element={<System/>}/>
//         <Route path={`${t('routes.inventory')}`} element={<Inventory/>}/>
//         <Route path={`${t('routes.cases')}`} element={<Cases/>}/>
//         <Route path="*" element={<NotFound />} /> {/* Add the 404 route */}
//         <Route path={`impairment`} element={<Impairment/>}/>
//         <Route path={`landing-page`} element={<LandingPage/>}/>

//       </Routes>
//     </Router>
//     <Footer/>
//     </>
//   );
// }

// export default App;

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams,Outlet  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Navibar from './components/navbar/navbar';
import Home from './components/home/home';
import System from './components/system/system';
import Inventory from './components/inventory/inventory';
import Cases from './components/cases/cases';
import Impairment from './components/impairment/impairment';
import LandingPage from './components/landingPage/landingPage';
import WhatsAppWidget from './components/whatsapp/whatsapp';
import './App.css';
import Footer from './components/footer/footer';
import NotFound from './components/404/404';

// Language router component that handles language changes
function LanguageRouter() {
  const { i18n } = useTranslation();
  const { lng } = useParams();
  
  useEffect(() => {
    if (lng && i18n.language !== lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return <Outlet />;
}

// Main content component with routes
function MainContent() {
  const { t } = useTranslation();
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={t('routes.system')} element={<System />} />
      <Route path={t('routes.inventory')} element={<Inventory />} />
      <Route path={t('routes.cases')} element={<Cases />} />
      <Route path="impairment" element={<Impairment />} />
      <Route path={t('routes.landing-page')} element={<LandingPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function App() {
  const { i18n } = useTranslation();
  const supportedLanguages = ['en', 'es', 'pt-BR']; // Add all languages you support

  return (
    <>
      <Navibar />
      <WhatsAppWidget />

      <Router>
        <Routes>
          {/* Redirect from root to language-prefixed route */}
          <Route path="/" element={<Navigate to={`/${i18n.language}`} replace />} />
          
          {/* Language-prefixed routes */}
          <Route path="/:lng" element={<LanguageRouter />}>
            <Route index element={<Home />} />
            <Route path="*" element={<MainContent />} />
          </Route>
          
          {/* Fallback for unsupported languages */}
          <Route path="*" element={<Navigate to={`/${i18n.fallbackLng}`} replace />} />
        </Routes>
      </Router>

      <Footer />
    </>
  );
}

export default App;