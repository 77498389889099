import React, { useEffect } from 'react';
import ContactForm from '../form/form';
import './impairment.scss';
import { useTranslation } from 'react-i18next';
import ZigZag from '../ZigZag/ZigZag';


function Impairment() {
  const { t } = useTranslation();

    return (

        <section className='impairment'>
            <div className="header-image">
                <h1 className = "impairment-heading">
                    {t('impairment_page_title')}
                </h1>
                <img src="/images/notebook.webp" alt="Header" />
            </div>
          
            <ZigZag
              items={[
                {
                    imageSrc: "/images/20943964.jpg",
                    imageAlt: "Impairment Scale",
                    title: t('impairment_page_content_1_title'),
                    description: t('impairment_page_content_1_content_1') + ' ' + t('impairment_page_content_1_content_2'),
                    listItems: [
                      t('impairment_page_list_1_strong_1') + ' ' + t('impairment_page_list_1_1'),
                      t('impairment_page_list_1_strong_2') + ' ' + t('impairment_page_list_1_2'),
                      t('impairment_page_list_1_strong_3') + ' ' + t('impairment_page_list_1_3')
                    ],
                    imageAttribution: "Designed by vectorjuice / Freepik",
                    imageAttributionLink: "https://www.freepik.com/"
                },
                {
                    imageSrc: "/images/20943661.jpg",
                    imageAlt: "Impairment Analysis",
                    title: t('impairment_page_content_2_title'),
                    description: t('impairment_page_content_2_content'),
                    listItems: [
                      t('impairment_page_list_2_1'),
                      t('impairment_page_list_2_2'),
                      t('impairment_page_list_2_3')
                    ],
                    imageAttribution: "Designed by vectorjuice / Freepik",
                    imageAttributionLink: "https://www.freepik.com/"
                }
              ]}
            />
            <ContactForm/>
        </section>
    );
        
}

export default Impairment;