import React, { useEffect } from "react";
import "./inventory.scss";
import ContactForm from "../form/form";
import ZigZag from "../ZigZag/ZigZag";
import { useTranslation } from "react-i18next";

function Inventory() {
  const { t } = useTranslation();

  return (
    <div className="inventory-container">
      <div className="header-image">
        <h1 className="inventory-heading">{t("inventory_page_title")}</h1>
        <img src="/images/factory.jpg" alt="Inventário" />
      </div>
      <ZigZag
        items={[
          {
            imageSrc: "/images/undraw_growth-curve_kzjb.svg",
            imageAlt: "Service 2",
            title: t("inventory_page_content_2_title"),
            description:
              t("inventory_page_content_2_text_1") +
              t("inventory_page_content_2_text_3"),
          },
          {
            imageSrc: "/images/undraw_engineering-team_13ax.svg",
            imageAlt: "Service 1",
            title: t("inventory_page_content_1_title"),
            description: t("inventory_page_content_1_text"),
            listItems: [
              t("inventory_page_list_1_item_1"),
              t("inventory_page_list_1_item_2"),
              t("inventory_page_list_1_item_3"),
              t("inventory_page_list_1_item_4"),
              t("inventory_page_list_1_item_5"),
            ],
          },
        ]}
      />
      <ContactForm />
    </div>
  );
}

export default Inventory;
