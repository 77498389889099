import React from 'react';
import './clients.scss';  // Import custom SCSS for styling

function Clients() {
  const clientLogos = [
    '/images/logo1.webp',
    '/images/logo2.webp',
    '/images/logo3.webp',
    '/images/logo4.webp',
    '/images/logo5.webp',
    '/images/logo6.webp',
    '/images/logo7.webp',
    '/images/logo8.webp',
    '/images/logo9.webp',
    '/images/logo10.webp',
    '/images/logo11.webp',
    '/images/logo12.webp',
    '/images/logo13.webp',
    '/images/logo14.webp',
    '/images/logo15.webp',
    '/images/logo16.webp',
    '/images/logo17.webp',
    '/images/logo18.webp',
    '/images/logo19.webp',
    '/images/logo20.webp',
    '/images/logo21.gif',
    '/images/logo22.webp',
    '/images/logo23.png',
    '/images/logo24.png',
    '/images/logo25.webp',
    '/images/logo26.webp',
    '/images/logo27.webp',
    '/images/logo28.webp',
    '/images/logo29.webp',
    '/images/logo30.png',
    '/images/logo31.png',
    '/images/logo32.webp',
    '/images/logo33.webp',
    '/images/logo34.webp',
    '/images/logo35.webp',
    '/images/logo36.webp',
    '/images/logo37.png',
    '/images/logo38.gif',
    '/images/logo39.png',
    '/images/logo40.png'
 
  ];

  console.log(clientLogos.length);

  return (
    <div className="clients-container">
      <div className="logos-slider">
        {clientLogos.map((logo, index) => (
          <div className="logo-item" key={index}>
            <img src={logo} className="client-logo" alt={`Client ${index + 1}`} />
          </div>
        ))}
        {clientLogos.map((logo, index) => (
          <div className="logo-item" key={index + clientLogos.length}>
            <img src={logo} className="client-logo" alt={`Client ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Clients;