import React from 'react';
import './landingPage.scss';  
import ContactForm from '../form/form';
import Clients from '../clients/clients';
import FeatureCards from '../home/system-cards/feature-cards';
import ZigZag from '../ZigZag/ZigZag';
import Services from '../home/services/services';
import { FaBalanceScale} from 'react-icons/fa';
import { LuNotebookPen } from "react-icons/lu";
import { GrFolderCycle } from "react-icons/gr";
import { FaMagnifyingGlassDollar } from "react-icons/fa6";
import { IoDocumentOutline } from "react-icons/io5";
import { BsClipboard2Check } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import Hero from './Hero/Hero';

function LandingPage() {
    const { i18n, t } = useTranslation();
    return (
        <div className="landingPage">
            <Hero />
            <Clients/>
            <FeatureCards
             cardsData={[
                {
                  imageSrc: "/images/undraw_business-plan_wv9q.png",
                  imageAlt: "Methodology Feature",
                  title: t('landing_page.cards.methodology.title'),
                  content: t('landing_page.cards.methodology.text')
                },
                {
                  imageSrc: "/images/android_ilustration.webp",
                  imageAlt: "System Feature",
                  title: t('landing_page.cards.system.title'),
                  content: t('system_card_content_2')
                },
                {
                  imageSrc: "/images/undraw_people_ka7y.png",
                  imageAlt: "People Feature",
                  title: t('landing_page.cards.team.title'),
                  content: t('landing_page.cards.team.text')
                }
              ]}
              buttonText={t('learn_more')}
              buttonLink="/"
              heading={t('landing_page.cards.heading')}
            />
            <Services
            services = {[
              {
                icon: <BsClipboard2Check/>,
                title: t('landing_page.services.fixed_asset.title'),
                description: t('landing_page.services.fixed_asset.description')
              },
              {
                icon: <FaBalanceScale />,
                title: t('landing_page.services.evaluation.title'),
                description: t('landing_page.services.evaluation.description')
              },
              {
                icon: <LuNotebookPen />,
                title: t('landing_page.services.conciliation.title'),
                description: t('landing_page.services.conciliation.description')
              },
              {
                  icon:<FaMagnifyingGlassDollar />,
                  title: t('landing_page.services.stock.title'),
                  description: t('landing_page.services.stock.description')
                },
                {
                  icon: <GrFolderCycle />,
                  title: t('landing_page.services.system.title'),
                  description: t('landing_page.services.system.description')
                },
                {
                  icon: <IoDocumentOutline />,
                  title: t('landing_page.services.documents.title'),
                  description: t('landing_page.services.documents.description')
                },
            ]}
            heading={t('home_services_heading')} 
            />
             <ZigZag
              items={[
                {
                    imageSrc: "/images/Jan-Business_team_3.jpg",
                    imageAlt: "Service 1",
                    title: t('landing_page.zigzag.first.title'),
                    description : t('landing_page.zigzag.first.description'),
                    ctaButton: t('learn_more'),
                    ctaLink: `/${i18n.language}/${t('routes.cases')}`,
                    imageAttribution: "Designed by Freepik",
                    imageAttributionLink: "https://www.freepik.com/",
                    listItems: [
                      t('landing_page.zigzag.first.list.0'),            
                      t('landing_page.zigzag.first.list.1'),
                    ]

                },
                {
                    imageSrc: "/images/Mar-Business_6.jpg",
                    imageAlt: "Service 2",
                    title: t('landing_page.zigzag.second.title'),
                    description: t('landing_page.zigzag.second.description'),
                    ctaButton: t('learn_more'),
                    ctaLink: `/${i18n.language}/${t('routes.system')}`,
                    imageAttribution: "Designed by Freepik",
                    imageAttributionLink: "https://www.freepik.com/"
                },

              ]}
            />
            <ContactForm/>
        </div>
    );
}

export default LandingPage;